import React from 'react'
import Sidebar from './Sidebar';

function RoyalGrandHostel() {
  return (
   <>
   
   <div>
<Sidebar />
   </div>
   
   </>
  )
}

export default RoyalGrandHostel;